import React, { useEffect, useState } from "react";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";
import { Program, AnchorProvider, web3 } from "@project-serum/anchor";
import { ChevronsRight } from "lucide-react";

import kp from "../keypair.json";
import pkp from "../publisherkeypair.json";

import { Buffer } from "buffer";
import Layout from "../components/layout/Layout";
window.Buffer = Buffer;

const arr = Object.values(kp._keypair.secretKey);
const secret = new Uint8Array(arr);
const baseAccount = web3.Keypair.fromSecretKey(secret);

const parr = Object.values(pkp._keypair.secretKey);
const psecret = new Uint8Array(parr);
const initialPubAccount = web3.Keypair.fromSecretKey(psecret);

const programID = new PublicKey("EQYu1bjXZt3jp5X7eZcjQv1q3ReB9MQKt597sGmu5Dqi"); // your program ID: 7qB4Yx1rtnyhz11KfikMZTSUcAhPdLu1ofunrkBWfhfM

const network = clusterApiUrl("devnet");

const opts = {
  preflightCommitment: "processed",
};

const Publisher = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [randomAd, setRandomAd] = useState(null);
  const [adList, setAdList] = useState([]);
  const [publisherAccount, setPublisherAccount] = useState(initialPubAccount);

  const checkIfWalletIsConnected = async () => {
    if (window?.solana?.isPhantom) {
      console.log("Phantom wallet found!");
      try {
        const response = await window.solana.connect({ onlyIfTrusted: true });
        console.log(
          "Connected with Public Key:",
          response.publicKey.toString()
        );
        setWalletAddress(response.publicKey.toString());
      } catch (error) {
        console.error("User rejected the request:", error);
      }
    } else {
      alert("Solana object not found! Get a Phantom Wallet 👻");
    }
  };

  const connectWallet = async () => {
    const { solana } = window;
    if (solana) {
      try {
        const response = await solana.connect();
        console.log(
          "Connected with Public Key:",
          response.publicKey.toString()
        );
        setWalletAddress(response.publicKey.toString());
      } catch (error) {
        console.error("User rejected the request:", error);
      }
    }
  };

  const renderNotConnectedContainer = () => (
    <button
      onClick={connectWallet}
      className="py-2 mt-10 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
    >
      Connect Wallet <ChevronsRight size={20} />
    </button>
  );

  const selectAndIncrementImpressions = async (adId, publisherAccount) => {
    try {
      const provider = getProvider();
      const program = await getProgram();

      await program.rpc.selectAdAndIncrementImpressions(adId, {
        accounts: {
          baseAccount: baseAccount.publicKey,
          user: provider.wallet.publicKey,
          publisherAccount: publisherAccount, // Pass the publisher account public key
        },
      });

      console.log("Impressions incremented for ad with ID:", adId);
    } catch (error) {
      console.log("Error selecting ad and incrementing impressions:", error);
    }
  };

  //System Publisher site
  const showRandomAd = async () => {
    if (!adList || adList.length === 0) {
      console.log("No ads available.");
      return;
    }
    console.log("ME", publisherAccount);
    if (publisherAccount) {
      while (true) {
        const randomIndex = Math.floor(Math.random() * adList.length);
        const randomAd = adList[randomIndex];
        console.log("Ad is paused", randomAd.paused);
        if (!randomAd.paused) {
          selectAndIncrementImpressions(
            randomAd.id,
            publisherAccount.publicKey
          );
          console.log("Randomly selected ad:", randomAd);
          setRandomAd(randomAd);
          break;
        } else {
          console.log("Ad is paused");
        }
      }
    }
  };

  const getProvider = () => {
    const connection = new Connection(network, opts.preflightCommitment);
    const provider = new AnchorProvider(
      connection,
      window.solana,
      opts.preflightCommitment
    );
    return provider;
  };

  const getProgram = async () => {
    const idl = await Program.fetchIdl(programID, getProvider());
    return new Program(idl, programID, getProvider());
  };

  const fetchAdList = async () => {
    try {
      const program = await getProgram();
      const account = await program.account.baseAccount.fetch(
        baseAccount.publicKey
      );

      console.log("Fetched ad list:", account.adList);
      setAdList(account.adList);
    } catch (error) {
      console.log("Error fetching ad list:", error);
      setAdList(null);
    }
  };

  const checkPublisher = async () => {
    try {
      console.log(walletAddress);
      const res = await fetch(
        `https://triad.harshbanjare.me/api/get/${walletAddress}`,
        {
          method: "GET",
        }
      );
      console.log(res);
      const data = await res.json();

      fetchKeypair(data); // Fetch the newly created publisher's info
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKeypair = async (dataLink) => {
    try {
      const res = await fetch(dataLink);
      if (!res.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await res.json();
      console.log(data);
      const parr = Object.values(data._keypair.secretKey); // 'parr' should give public key output like one that is present in keypair.json
      const psecret = new Uint8Array(parr);
      const publisherAccount = web3.Keypair.fromSecretKey(psecret);
      setPublisherAccount(publisherAccount);
    } catch (error) {
      console.error("Error fetching publisher JSON:", error);
    }
  };

  //Admin, Advertiser, Publisher
  useEffect(() => {
    const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
  }, []);

  useEffect(() => {
    const onLoad = async () => {
      await checkPublisher();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
  }, [walletAddress]);

  useEffect(() => {
    if (walletAddress) {
      console.log("Fetching ad list...");
      fetchAdList();
    }
  }, [walletAddress, publisherAccount]);

  //Admin, Advertiser, Publisher
  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  useEffect(() => {
    checkPublisher();
  }, [walletAddress]);

  useEffect(() => {
    const timer = setTimeout(() => {
      showRandomAd();
    }, 1000);

    return () => clearTimeout(timer);
  }, [adList]);

  return (
    <>
      <div className="h-[100dvh] w-[100dvw] flex">
        {/* column 1 */}
        <div className="">
          {randomAd && (
            <div className="fixed w-[300px] h-full flex items-center z-20">
              <div>
                <div className="adback">
                  <a href={randomAd.linkToTarget} target="_blank">
                    <img
                      src={randomAd.mediaFileLink}
                      height={300}
                      alt={randomAd.name}
                    />
                  </a>
                  <p className="sub-text text-center">{randomAd.name}</p>
                  <p className="sub-text text-center">{randomAd.description}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* column 2 */}
        <Layout>
          <div className="min-h-[91dvh] w-[70%] mx-auto py-10">
            <div className={walletAddress ? "authed-container" : "container"}>
              <div className="header-container">
                <p className="text-6xl mx-auto py-10 text-center">
                  Example Web3 Blog
                </p>
                {!walletAddress && renderNotConnectedContainer()}
                <p className="text-6xl mx-auto py-10 text-center">
                  Best Laptops To Buy In 2024
                </p>
              </div>
              <p className="text-xl">
                Looking for one of the best laptops of 2024? You've arrived at
                exactly the right place as here at Laptop Mag we test dozens of
                laptops every year to find the best of the best. We already have
                some new arrivals to our list released this year with plenty of
                additional exciting laptops on the horizon with talk of AI PC,
                the intriguing Qualcomm Snapdragon X Elite chipset, and more on
                the horizon. But rest assured we have plenty of outstanding
                options for you right now. Whether you are on the search for an
                affordable Chromebook, a powerful gaming laptop, a flexible
                2-in-1 laptop, or a content creation laptop with a gorgeous
                display, our extensive reviews and testing at Laptop Mag will
                guide you to your perfect laptop.
              </p>
              <img src="https://cdn.mos.cms.futurecdn.net/oS7CEyVTZx3Re3eCRkV33W-1200-80.jpg.webp"></img>
              <p className="text-xl">
                Looking for one of the best laptops of 2024? You've arrived at
                exactly the right place as here at Laptop Mag we test dozens of
                laptops every year to find the best of the best. We already have
                some new arrivals to our list released this year with plenty of
                additional exciting laptops on the horizon with talk of AI PC,
                the intriguing Qualcomm Snapdragon X Elite chipset, and more on
                the horizon. But rest assured we have plenty of outstanding
                options for you right now. Whether you are on the search for an
                affordable Chromebook, a powerful gaming laptop, a flexible
                2-in-1 laptop, or a content creation laptop with a gorgeous
                display, our extensive reviews and testing at Laptop Mag will
                guide you to your perfect laptop.
              </p>
              <br></br>
              <p className="text-xl">
                Looking for one of the best laptops of 2024? You've arrived at
                exactly the right place as here at Laptop Mag we test dozens of
                laptops every year to find the best of the best. We already have
                some new arrivals to our list released this year with plenty of
                additional exciting laptops on the horizon with talk of AI PC,
                the intriguing Qualcomm Snapdragon X Elite chipset, and more on
                the horizon. But rest assured we have plenty of outstanding
                options for you right now. Whether you are on the search for an
                affordable Chromebook, a powerful gaming laptop, a flexible
                2-in-1 laptop, or a content creation laptop with a gorgeous
                display, our extensive reviews and testing at Laptop Mag will
                guide you to your perfect laptop.
              </p>
            </div>
          </div>
        </Layout>
        {/* column 3 */}
        <div className="">
          {randomAd && (
            <div className="fixed w-[300px] h-full flex items-center right-0">
              <br />
              <div>
                <div className="adback">
                  <a href={randomAd.linkToTarget} target="_blank">
                    <img
                      src={randomAd.mediaFileLink}
                      height={300}
                      alt={randomAd.name}
                    />
                  </a>
                  <p className="sub-text text-center">{randomAd.name}</p>
                  <p className="sub-text text-center">{randomAd.description}</p>
                </div>
              </div>
              <br />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Publisher;
