import React from "react";
import { Link } from "react-router-dom";
import { logo, logo2 } from "../../assets";

const Header = () => {
  return (
    <header className="px-28">
      <div className="flex items-center justify-between border-b border-b-white py-1 z-10">
        <div className="z-10 flex h-7">
          <img src={logo2} alt="Triad" className="" />
          <img src={logo} alt="Triad" className="" />
          {/* <h1 className="font-m font-bold text-2xl">TRIAD</h1> */}
        </div>
        <nav className="z-10">
          <div className="flex items-center gap-10">
            <Link
              className="font-m font-light text-sm hover:font-semibold transition-all"
              to="/"
            >
              HOME
            </Link>

            <Link
              className="font-m font-light text-sm hover:font-semibold transition-all"
              to="/admin"
            >
              ADMIN PANEL
            </Link>

            <Link
              className="font-m font-light text-sm hover:font-semibold transition-all"
              to="/publisher"
            >
              PUBLISHER
            </Link>

            <Link
              className="font-m font-light text-sm hover:font-semibold transition-all"
              to="/advertiser"
            >
              ADVERTISER
            </Link>

            <Link
              className="font-m font-light text-sm hover:font-semibold transition-all"
              to="/example"
            >
              EXAMPLE SITE
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
