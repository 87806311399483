import React from "react";

const Strategy = () => {
  return (
    <div className="h-full flex items-center">
      <div className="flex-1 h-full flex items-center gap-20 justify-center">
        <div
          data-aos="fade-left"
          className="bg-[#0f2443] p-5 w-[35%] rounded-lg shadow-lg border-[.5px] border-[#2aa0e1] hover:shadow-[#2aa0e1] transition-all duration-500"
        >
          <h2 className="font-m text-[2rem] font-bold">Growth Strategy</h2>
          <br />
          <ul className="list-disc ps-10">
            <li>
              <p className="font-m font-light">
                Partnerships with advertisers and publishers, community
                building, and platform incentives.
              </p>
            </li>
            <br />
            <li>
              <p className="font-m font-light">
                Hassle-free integration of ads into apps and platforms built on
                other blockchains using Wormhole.
              </p>
            </li>
            <br />
            <li>
              <p className="font-m font-light">
                Using PDAs to provide make the processes frictionless, and serve
                ads to Web2 platforms
              </p>
            </li>
            <br />
            <li>
              <p className="font-m font-light">Rewarding end-users.</p>
            </li>
          </ul>
        </div>

        <div className="w-[1px] bg-white rounded-full h-1/2"></div>

        <div
          data-aos="fade-right"
          className="bg-[#0f2443] p-5 w-[35%] rounded-lg shadow-lg border-[.5px] border-[#2aa0e1] hover:shadow-[#2aa0e1] transition-all duration-500"
        >
          <h2 className="font-m text-[2rem] font-bold">
            Go-to-Market Strategy
          </h2>
          <br />
          <ul className="list-disc ps-10">
            <li>
              <p className="font-m font-light">
                <span className="font-bold">
                  Community-Driven Growth: <br />
                </span>
                Utilize Solana's blockchain community, engage decentralized
                social platforms, partner with influencers, and projects for
                amplified reach and credibility.
              </p>
            </li>
            <br />
            <li>
              <p className="font-m font-light">
                <span className="font-bold">
                  Web3-Native Marketing: <br />
                </span>
                Utilize on-chain airdrops and bounty programs to incentivize
                user engagement and brand awareness. Develop NFT-based marketing
                campaigns to connect with crypto enthusiasts in a unique and
                memorable way.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Strategy;
