import { Html, useProgress } from "@react-three/drei";
import { jellyTriangle } from "ldrs";
import { useEffect } from "react";

const CanvasLoader = () => {
  const { progress } = useProgress();

  useEffect(() => {
    jellyTriangle.register();
  }, []);
  return (
    <Html
      as="div"
      center
      //   style={{
      //     display: "flex",
      //     justifyContent: "center",
      //     alignItems: "center",
      //     flexDirection: "column",
      //   }}
      className="h-full w-full fixed top-0 left-0 flex justify-center items-center"
    >
      {/* <span className="canvas-loader"></span> */}
      {/* <p
        style={{
          fontSize: 14,
          color: "#F1F1F1",
          fontWeight: 800,
          marginTop: 40,
        }}
      >
        {progress.toFixed(2)}%
      </p> */}

      <l-jelly-triangle
        size="30"
        speed="1.75"
        color="#6a81fb"
      ></l-jelly-triangle>
    </Html>
  );
};

export default CanvasLoader;
