import React from "react";
import { harsh, hasaan, rizwan, satya } from "../assets";

const Team = () => {
  return (
    <div className="h-full flex items-center">
      <div className="flex-1">
        <h1 className="font-m font-bold text-[4rem] text-center">Our Team</h1>
        <div className="flex justify-around items-center mt-20 w-full">
          {/* 1  */}
          <div className="flex flex-col items-center gap-2 h-[360px]">
            <div className="g-box rounded-[2.5rem] p-[1.5px]">
              <div className="rounded-[2.5rem] h-[220px] w-[220px] overflow-hidden relative">
                {/* <div className="h-full w-full absolute bg-gradient-to-br from-[#6a81fb] to-[#fc5c7d] opacity-45"></div> */}
                <img src={rizwan} alt="Muhammad Rizwan" />
              </div>
            </div>
            <div className="w-[200px]">
              <h2 className="font-m font-bold text-lg text-center">
                Muhammad Rizwan
              </h2>
              <h5 className="font-m font-medium text-sm text-center">
                Blockchain Engineer
              </h5>
              <p className="font-m font-light text-xs text-center mt-1">
                The leader driving business processes, adept at transforming
                ideas into technologically robust solutions, particularly within
                blockchain technology.
              </p>
            </div>
          </div>

          {/* 2 */}
          <div className="flex flex-col items-center gap-2 h-[360px]">
            <div className="g-box rounded-[2.5rem] p-[1.5px]">
              <div className="rounded-[2.5rem] h-[220px] w-[220px] overflow-hidden relative">
                {/* <div className="h-full w-full absolute bg-gradient-to-br from-[#6a81fb] to-[#fc5c7d] opacity-45"></div> */}
                <img src={hasaan} alt="Hasaan M Qadri" />
              </div>
            </div>
            <div className="w-[200px]">
              <h2 className="font-m font-bold text-lg text-center">
                Hasaan M Qadri
              </h2>
              <h5 className="font-m font-medium text-sm text-center">
                Operations Manager
              </h5>
              <p className="font-m font-light text-xs text-center mt-1">
                The brain behind business processes. Responsible for turning
                ideas into visually compelling works.
              </p>
            </div>
          </div>

          {/* 3 */}
          <div className="flex flex-col items-center gap-2 h-[360px]">
            <div className="g-box rounded-[2.5rem] p-[1.5px]">
              <div className="rounded-[2.5rem] h-[220px] w-[220px] overflow-hidden relative">
                {/* <div className="h-full w-full absolute bg-gradient-to-br from-[#6a81fb] to-[#fc5c7d] opacity-45"></div> */}
                <img src={harsh} alt="Harsh Banjare" />
              </div>
            </div>
            <div className="w-[200px]">
              <h2 className="font-m font-bold text-lg text-center">
                Harsh Banjare
              </h2>
              <h5 className="font-m font-medium text-sm text-center">
                Cloud Architect
              </h5>
              <p className="font-m font-light text-xs text-center mt-1">
                The creative force behind our business. Developer with 7 years
                of experience in the tech space.
              </p>
            </div>
          </div>

          {/* 4 */}
          <div className="flex flex-col items-center gap-2 h-[360px]">
            <div className="g-box rounded-[2.5rem] p-[1.5px]">
              <div className="rounded-[2.5rem] h-[220px] w-[220px] overflow-hidden relative">
                {/* <div className="h-full w-full absolute bg-gradient-to-br from-[#6a81fb] to-[#fc5c7d] opacity-45"></div> */}
                <img src={satya} alt="Satya Prakash" />
              </div>
            </div>
            <div className="w-[200px]">
              <h2 className="font-m font-bold text-lg text-center">
                Satya Prakash
              </h2>
              <h5 className="font-m font-medium text-sm text-center">
                Fullstack Developer
              </h5>
              <p className="font-m font-light text-xs text-center mt-1">
                The brain behind business online apparel. Developer with a knack
                for crafting seamless user experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
