import React, { useEffect, useState } from "react";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";
import { Program, AnchorProvider, web3 } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";
import axios from "axios";
import { Bar } from "react-chartjs-2";

import kp from "../keypair.json";
import pkp from "../publisherkeypair.json";

import { Buffer } from "buffer";
import Layout from "../components/layout/Layout";
import { ChevronsRight } from "lucide-react";
window.Buffer = Buffer;

const { SystemProgram } = web3;

const arr = Object.values(kp._keypair.secretKey);
const secret = new Uint8Array(arr);
const baseAccount = web3.Keypair.fromSecretKey(secret);

const programID = new PublicKey("EQYu1bjXZt3jp5X7eZcjQv1q3ReB9MQKt597sGmu5Dqi"); // your program ID: 7qB4Yx1rtnyhz11KfikMZTSUcAhPdLu1ofunrkBWfhfM

const network = clusterApiUrl("devnet");

const opts = {
  preflightCommitment: "processed",
};

const Publisher = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [publisherInfo, setPublisherInfo] = useState(null);
  const [publisherSiteUrl, setPublisherSiteUrl] = useState("");
  const [randomAd, setRandomAd] = useState(null);
  const [adList, setAdList] = useState([]);
  const [publisherAccount, setPublisherAccount] = useState(null);
  const [site, setSite] = useState("");

  const addPublisher = async (address) => {
    try {
      console.log(address);
      const res = await fetch(`https://triad.harshbanjare.me/api/save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          wallet_address: address,
        }),
      });
      console.log(res);
      const data = await res.json();
      console.log(data);
      //Trying to create, save, fetch and save publisher account
      checkPublisher();
    } catch (error) {
      console.log(error);
    }
  };

  const checkPublisher = async () => {
    try {
      console.log(walletAddress);
      const res = await fetch(
        `https://triad.harshbanjare.me/api/get/${walletAddress}`,
        {
          method: "GET",
        }
      );
      console.log(res);
      const data = await res.json();

      fetchKeypair(data); // Fetch the newly created publisher's info
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkPublisher();
  }, [walletAddress]);

  //--------Work Here----------------------------------------
  const fetchKeypair = async (dataLink) => {
    try {
      const res = await fetch(dataLink);
      if (!res.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await res.json();

      const parr = Object.values(data._keypair.secretKey); // 'parr' should give public key output like one that is present in keypair.json
      const psecret = new Uint8Array(parr);
      const publisherAccount = web3.Keypair.fromSecretKey(psecret);
      setPublisherAccount(publisherAccount);

      const program = await getProgram();
      const info = await program.account.publisherAccount.fetch(
        publisherAccount.publicKey
      );
      setPublisherInfo(info);
    } catch (error) {
      console.error("Error fetching publisher JSON:", error);
    }
  };

  const checkIfWalletIsConnected = async () => {
    if (window?.solana?.isPhantom) {
      console.log("Phantom wallet found!");
      try {
        const response = await window.solana.connect({ onlyIfTrusted: true });
        console.log(
          "Connected with Public Key:",
          response.publicKey.toString()
        );
        setWalletAddress(response.publicKey.toString());
      } catch (error) {
        console.error("User rejected the request:", error);
      }
    } else {
      alert("Solana object not found! Get a Phantom Wallet 👻");
    }
  };

  const connectWallet = async () => {
    const { solana } = window;
    if (solana) {
      try {
        const response = await solana.connect();
        console.log(
          "Connected with Public Key:",
          response.publicKey.toString()
        );
        setWalletAddress(response.publicKey.toString());
      } catch (error) {
        console.error("User rejected the request:", error);
      }
    }
  };

  //System Publisher site
  const showRandomAd = () => {
    if (!adList || adList.length === 0) {
      console.log("No ads available.");
      return;
    }
    while (true) {
      const randomIndex = Math.floor(Math.random() * adList.length);
      const randomAd = adList[randomIndex];
      console.log("Ad is paused", randomAd.paused);
      if (!randomAd.paused) {
        // selectAndIncrementImpressions(randomAd.id, publisherAccount.publicKey);
        console.log("Randomly selected ad:", randomAd);
        setRandomAd(randomAd);
        break;
      } else {
        console.log("Ad is paused");
      }
    }
  };

  const getProvider = () => {
    const connection = new Connection(network, opts.preflightCommitment);
    const provider = new AnchorProvider(
      connection,
      window.solana,
      opts.preflightCommitment
    );
    return provider;
  };

  const createPublisher = async () => {
    if (!walletAddress) {
      console.log("Wallet is not connected");
      return;
    }
    if (!publisherSiteUrl) {
      console.log("Site URL is required");
      return;
    }
    try {
      const provider = getProvider();
      const program = await getProgram();
      await program.rpc.createPublisher(
        publisherAccount.publicKey,
        publisherSiteUrl,
        {
          accounts: {
            publisher: publisherAccount.publicKey,
            user: provider.wallet.publicKey,
            systemProgram: SystemProgram.programId,
          },
          signers: [publisherAccount],
        }
      );
      console.log("Publisher created successfully");
      window.alert("Publisher created successfully");
    } catch (error) {
      console.error("Error creating publisher:", error);
    }
  };

  const onPublisherSiteUrlChange = (event) => {
    setPublisherSiteUrl(event.target.value);
  };

  const renderNotConnectedContainer = () => (
    <button
      className="cta-button connect-wallet-button"
      onClick={connectWallet}
    >
      Connect to Wallet
    </button>
  );

  const renderConnectedContainer = () => {
    if (adList === null) {
      return (
        <div className="connected-container">
          <p className="header">No Ads Created</p>
        </div>
      );
    } else {
      return (
        <div className="mt-10">
          {!publisherAccount && (
            <div className="flex justify-center items-center">
              <p className="mr-2 text-3xl">
                First Initialize The Publisher Account Then Create The Account{" "}
              </p>
              <button
                onClick={() => addPublisher(walletAddress)}
                type="submit"
                className="py-2 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] mt-4 mb-4 font-m font-semibold flex items-center gap-1 active:scale-[.98]"
              >
                Initialize Publisher
              </button>
            </div>
          )}
          {!publisherInfo && (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                createPublisher();
              }}
              className="flex items-center justify-center gap-3"
            >
              <input
                type="text"
                className="bg-black border border-gray-400 rounded-md py-2 px-3 focus:outline-0 min-w-[400px]"
                placeholder="Enter Site URL"
                value={publisherSiteUrl}
                onChange={onPublisherSiteUrlChange}
              />
              <button
                onClick={() => addPublisher(walletAddress)}
                type="submit"
                className="py-2 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
              >
                Create Account
              </button>
            </form>
          )}

          {publisherInfo && (
            <div
              className="ad-item text-2xl justify-center">
              <p>Publisher Site URL: {publisherInfo.site}</p>
              <p>Impressions: {publisherInfo.impressions.toString(10)}</p>
              <p>Clicks: {publisherInfo.clicks.toString(10)}</p>
              <p>Earnings: {publisherInfo.earnings.toString(10)}</p>
              <p>Account Pubkey: {publisherAccount.publicKey.toString(10)}</p>
              {/* <p>Creator Pubkey: {publisherInfo.creatorPubkey.toString(10)}</p> */}
            </div>
          )}
        </div>
      );
    }
  };

  const getProgram = async () => {
    const idl = await Program.fetchIdl(programID, getProvider());
    return new Program(idl, programID, getProvider());
  };

  const fetchAdList = async () => {
    try {
      const program = await getProgram();
      const account = await program.account.baseAccount.fetch(
        baseAccount.publicKey
      );

      console.log("Fetched ad list:", account.adList);
      setAdList(account.adList);
    } catch (error) {
      console.log("Error fetching ad list:", error);
      setAdList(null);
    }
  };

  //Admin, Advertiser, Publisher
  useEffect(() => {
    const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
  }, []);

  useEffect(() => {
    if (walletAddress) {
      console.log("Fetching ad list...");
      fetchAdList();
    }
  }, [walletAddress]);

  //Admin, Advertiser, Publisher
  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  useEffect(() => {
    // Call showRandomAd when the component loads
    showRandomAd();
  }, [adList]);

  return (
    <Layout>
      <div className="min-h-[91dvh]">
        {!walletAddress ? (
          <div className="h-[91dvh] flex justify-center items-center">
            <button
              onClick={connectWallet}
              className="py-2 mt-10 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
            >
              Connect Wallet <ChevronsRight size={20} />
            </button>
          </div>
        ) : (
          <div className="py-10">
            <h1 className="text-center font-m font-bold text-xl">
              Publisher Dashboard
            </h1>
            <div className="flex justify-center mt-3 ">
              <p className="font-m font-bold text-base bg-[#1F1F1F] px-5 rounded-sm">
                Wallet Address :{" "}
                <span className="font-light">{walletAddress}</span>
              </p>
            </div>

            {walletAddress && renderConnectedContainer()}

            <div className="mt-10">
              <p className="text-center font-m font-bold text-xl">
                Advertisement Display
              </p>
              <div className="flex justify-center mt-5">
                {randomAd && (
                  <div className="border border-gray-400 rounded-lg p-5">
                    <div className="h-[400px] overflow-hidden">
                      <a className="h-full w-full" href={randomAd.linkToTarget}>
                        <img
                          className="h-full w-full"
                          src={randomAd.mediaFileLink}
                          height={400}
                          alt=""
                        />
                      </a>
                    </div>
                    <p className="font-m font-bold text-base px-5 rounded-sm mt-5">
                      Ad ID :<span className="font-light">{randomAd.id}</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Publisher;
