import React, { useEffect, useState } from "react";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";
import { Program, AnchorProvider, web3 } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";
import kp from "../keypair.json";

import { Buffer } from "buffer";
import Layout from "../components/layout/Layout";
import { ChevronsRight } from "lucide-react";
import UpdateModal from "../components/UpdateModal";
window.Buffer = Buffer;

const { SystemProgram } = web3;

const Advertiser = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [adList, setAdList] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [mediaFileLink, setMediaFileLink] = useState("");
  const [linkToTarget, setLinkToTarget] = useState("");
  const [days, setDays] = useState("");
  const [costPerDay, setCostPerDay] = useState("");
  const [impressionsPerDay, setImpressionsPerDay] = useState("");
  const [budget, setBudget] = useState("");
  const [id, setId] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);

  const network = clusterApiUrl("devnet");
  const opts = { preflightCommitment: "processed" };
  const arr = Object.values(kp._keypair.secretKey);
  const secret = new Uint8Array(arr);
  const baseAccount = web3.Keypair.fromSecretKey(secret);
  const programID = new PublicKey(
    "EQYu1bjXZt3jp5X7eZcjQv1q3ReB9MQKt597sGmu5Dqi"
  );

  const checkIfWalletIsConnected = async () => {
    if (window?.solana?.isPhantom) {
      console.log("Phantom wallet found!");
      try {
        const response = await window.solana.connect({ onlyIfTrusted: true });
        console.log(
          "Connected with Public Key:",
          response.publicKey.toString()
        );
        setWalletAddress(response.publicKey.toString());
      } catch (error) {
        console.error("User rejected the request:", error);
      }
    } else {
      alert("Solana object not found! Get a Phantom Wallet 👻");
    }
  };

  const connectWallet = async () => {
    const { solana } = window;
    if (solana) {
      const response = await solana.connect();
      console.log("Connected with Public Key:", response.publicKey.toString());

      setWalletAddress(response.publicKey.toString());
    }
  };

  const sendAdCampaign = async () => {
    if (
      !name ||
      !description ||
      !mediaFileLink ||
      !linkToTarget ||
      !days ||
      !costPerDay ||
      !impressionsPerDay ||
      !budget
    ) {
      console.log("Missing ad campaign details!");
      return;
    }

    try {
      const provider = getProvider();
      const program = await getProgram();

      await program.rpc.createCampaign(
        name,
        description,
        mediaFileLink,
        linkToTarget,
        new anchor.BN(Math.floor(Date.now() / 1000)),
        new anchor.BN(Math.floor(Date.now() / 1000) + days * 24 * 60 * 60),
        new anchor.BN(days),
        new anchor.BN(costPerDay),
        new anchor.BN(impressionsPerDay),
        new anchor.BN(budget),
        {
          accounts: {
            baseAccount: baseAccount.publicKey,
            user: provider.wallet.publicKey,
          },
        }
      );

      console.log("Ad campaign created successfully");

      setName("");
      setDescription("");
      setMediaFileLink("");
      setLinkToTarget("");
      setDays("");
      setCostPerDay("");
      setImpressionsPerDay("");
      setBudget("");

      await fetchAdList();
    } catch (error) {
      console.error("Error creating ad campaign:", error);
    }
  };

  const updateAdCampaign = async () => {
    if (!id) {
      console.log("Missing ad campaign ID!");
      return;
    }

    try {
      const provider = getProvider();
      const program = await getProgram();

      await program.rpc.updateCampaign(
        id,
        name || null,
        description || null,
        mediaFileLink || null,
        linkToTarget || null,
        {
          accounts: {
            baseAccount: baseAccount.publicKey,
            user: provider.wallet.publicKey,
          },
        }
      );

      console.log("Ad campaign updated successfully");

      setName("");
      setDescription("");
      setMediaFileLink("");
      setLinkToTarget("");
      setId("");

      setShow(!show);

      await fetchAdList();
    } catch (error) {
      console.error("Error updating ad campaign:", error);
    }
  };

  const handleImpressionsChange = (value) => {
    setImpressionsPerDay(value);
    const cost = value * 1;
    setCostPerDay(cost);
    const newBudget = cost * days;
    setBudget(newBudget);
  };

  const handleDaysChange = (value) => {
    setDays(value);
    const newBudget = costPerDay * value;
    setBudget(newBudget);
  };

  const renderForms = () => (
    <div className="forms-container">
      <div className="form">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            sendAdCampaign();
          }}
        >
          <input
            style={{ width: "45%" }}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          <input
            style={{ width: "45%" }}
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />
          <input
            type="text"
            value={mediaFileLink}
            onChange={(e) => setMediaFileLink(e.target.value)}
            placeholder="Media File Link"
          />
          <input
            type="text"
            value={linkToTarget}
            onChange={(e) => setLinkToTarget(e.target.value)}
            placeholder="Link To Target"
          />
          <input
            type="number"
            value={days}
            onChange={(e) => handleDaysChange(e.target.value)}
            placeholder="Days"
          />
          <select
            value={impressionsPerDay}
            onChange={(e) => handleImpressionsChange(e.target.value)}
          >
            <option value="" disabled selected>
              Select Impressions Per Day
            </option>
            <option value="1000">1000</option>
            <option value="10000">10000</option>
            <option value="100000">100000</option>
          </select>
          <input
            type="number"
            value={costPerDay}
            placeholder="Cost Per Day"
            readOnly
          />
          <input type="number" value={budget} placeholder="Budget" readOnly />
          <button
            style={{ width: "95%", marginTop: "40px" }}
            type="submit"
            className="cta-button submit-gif-button"
          >
            Create Ad Campaign
          </button>
        </form>
      </div>

      <div className="form">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            updateAdCampaign();
          }}
        >
          <input
            style={{ width: "90%" }}
            type="text"
            value={id}
            onChange={(e) => setId(e.target.value)}
            placeholder="Ad Id"
          />
          <input
            style={{ width: "45%" }}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          <input
            style={{ width: "45%" }}
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />
          <input
            style={{ width: "45%" }}
            type="text"
            value={mediaFileLink}
            onChange={(e) => setMediaFileLink(e.target.value)}
            placeholder="Media File Link"
          />
          <input
            style={{ width: "45%" }}
            type="text"
            value={linkToTarget}
            onChange={(e) => setLinkToTarget(e.target.value)}
            placeholder="Link To Target"
          />
          <button
            style={{ width: "95%", marginTop: "40px" }}
            type="submit"
            className="cta-button submit-gif-button"
          >
            Update Ad Campaign
          </button>
        </form>
      </div>
    </div>
  );

  const renderConnectedContainer = () => {
    const filteredAds = adList.filter(
      (ad) => ad.userAddress.toString(10) === walletAddress
    );

    return (
      <div className="connected-container">
        <div>
          <div>{renderForms()}</div>
        </div>
        <div className="ad-list ad-item">
          {filteredAds.map((ad, index) => (
            <div className="card" key={index}>
              <br></br>
              <a href={ad.linkToTarget}>
                <img
                  className="image"
                  src={ad.mediaFileLink}
                  height={400}
                  alt=""
                ></img>
              </a>
              <br></br>
              <p>
                Ad ID:
                <br /> {ad.id}
              </p>
              <p>Impressions Count: {ad.impressionsCountPerDay.toString(10)}</p>
              <p>Name: {ad.name}</p>
              <p>Description: {ad.description}</p>
              <p>Link To Target: {ad.linkToTarget}</p>
              <p>
                Start Time: {new Date(ad.startTime * 1000).toLocaleString()}
              </p>
              <p>End Time: {new Date(ad.endTime * 1000).toLocaleString()}</p>
              <p>Days: {ad.days.toString(10)}</p>
              <p>Cost Per Day: {ad.costPerDay.toString(10)}</p>
              <p>Impression Per Day: {ad.impressionsPerDay.toString(10)}</p>
              <p>Budget: {ad.budget.toString(10)} Sol</p>
              <p>User Address: {ad.userAddress.toString(10)}</p>
              <br></br>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const filteredAds = adList?.filter(
    (ad) => ad.userAddress.toString(10) === walletAddress
  );

  useEffect(() => {
    console.log(filteredAds);
  }, [filteredAds]);

  const getProvider = () => {
    const connection = new Connection(network, opts.preflightCommitment);
    const provider = new AnchorProvider(
      connection,
      window.solana,
      opts.preflightCommitment
    );
    return provider;
  };

  const getProgram = async () => {
    const idl = await Program.fetchIdl(programID, getProvider());
    return new Program(idl, programID, getProvider());
  };

  const fetchAdList = async () => {
    try {
      const program = await getProgram();
      const account = await program.account.baseAccount.fetch(
        baseAccount.publicKey
      );

      setAdList(account.adList);
    } catch (error) {
      console.log("Error fetching ad list:", error);
      setAdList(null);
    }
  };

  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  useEffect(() => {
    if (walletAddress) {
      fetchAdList();
    }
  }, [walletAddress]);

  return (
    <>
      <Layout>
        <div className="min-h-[91dvh]">
          {!walletAddress ? (
            <div className="h-[91dvh] flex justify-center items-center">
              <button
                onClick={connectWallet}
                className="py-2 mt-10 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
              >
                Connect Wallet <ChevronsRight size={20} />
              </button>
            </div>
          ) : (
            <div className="py-10">
              <h1 className="text-center font-m font-bold text-xl">
                Advertisers Dashboard
              </h1>
              <div className="flex justify-center mt-3 ">
                <p className="font-m font-bold text-base bg-[#1F1F1F] px-5 rounded-sm">
                  Wallet Address :{" "}
                  <span className="font-light">{walletAddress}</span>
                </p>
              </div>
              <div className="px-40">
                <div className="mor-bg border border-gray-400 rounded-lg mt-10 py-5 px-10">
                  <h3 className="font-m font-bold text-base text-center">
                    Create Ad Campaign
                  </h3>

                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      sendAdCampaign();
                    }}
                  >
                    <div className="p-5 mt-5">
                      <div className="flex gap-10">
                        <div className="flex-1">
                          <div className="flex gap-2 items-center">
                            <p className="font-m font-semibold">Name : </p>
                            <div className="flex-1 flex">
                              <input
                                className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="flex gap-2 mt-5">
                            <p className="font-m font-semibold">
                              Description :{" "}
                            </p>
                            <div className="flex-1 flex">
                              <textarea
                                className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                                rows={5}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="flex mt-5 items-center">
                            <div className="flex-1 flex">
                              <select
                                className="flex-1 text-center bg-black border border-[#4B4B4B] rounded py-2 focus:outline-0 px-3 text-sm font-m hover:cursor-pointer"
                                value={impressionsPerDay}
                                onChange={(e) =>
                                  handleImpressionsChange(e.target.value)
                                }
                              >
                                <option value="" disabled selected>
                                  Select Impressions Per Day
                                </option>
                                <option value="1000">1000</option>
                                <option value="10000">10000</option>
                                <option value="100000">100000</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="flex-1">
                          <div className="flex gap-2 items-center justify-between">
                            <p className="font-m font-semibold">
                              Media File Link :{" "}
                            </p>
                            <div className="w-[60%] flex">
                              <input
                                className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                                type="text"
                                value={mediaFileLink}
                                onChange={(e) =>
                                  setMediaFileLink(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="flex mt-5 gap-2 items-center justify-between">
                            <p className="font-m font-semibold">
                              Link to Target :{" "}
                            </p>
                            <div className="w-[60%] flex">
                              <input
                                className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                                type="text"
                                value={linkToTarget}
                                onChange={(e) =>
                                  setLinkToTarget(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="flex mt-5 gap-2 items-center justify-between">
                            <p className="font-m font-semibold">Days : </p>
                            <div className="w-[60%] flex">
                              <input
                                className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                                type="number"
                                value={days}
                                onChange={(e) =>
                                  handleDaysChange(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="flex mt-5 gap-2 items-center justify-between">
                            <p className="font-m font-semibold">
                              Cost per Day :{" "}
                            </p>
                            <div className="w-[60%] flex">
                              <input
                                className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                                value={costPerDay}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-center items-center">
                        <div className="flex mt-5 gap-2 items-center">
                          <p className="font-m font-semibold">Budget : </p>
                          <div className="flex">
                            <input
                              className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                              type="number"
                              value={budget}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="py-2 mt-10 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {/* list ads */}
              <div className="flex flex-col gap-5 mt-10">
                {filteredAds?.map((a) => (
                  <div
                    key={a?.id}
                    className="border border-gray-400 rounded-lg p-5 w-full "
                  >
                    <div className="mb-5">
                      <h2 className="italic font-bold text-center text-lg">
                        {a?.name}
                      </h2>

                      <p className="italic font-thin text-center text-xs line-clamp-3">
                        {a?.description}
                      </p>
                    </div>

                    <div className="gap-5 flex">
                      <div className="w-[300px] h-full rounded-md overflow-hidden">
                        <img
                          className=""
                          src={a?.mediaFileLink}
                          alt={a?.name}
                        />
                      </div>

                      <div className="">
                        <div className="flex gap-2 items-center">
                          <p className="font-m font-semibold text-sm">
                            Ad ID:{" "}
                          </p>
                          <div className="">
                            <p className="font-m font-light text-sm">{a?.id}</p>
                          </div>
                        </div>

                        <div className="flex items-center mt-2">
                          <p className="font-m font-semibold text-sm">
                            Impressions Count:{" "}
                            <span className="font-m ml-2 font-light text-sm">
                              {a?.impressionsCountPerDay.toString(10)}
                            </span>
                          </p>
                        </div>

                        <div className="flex gap-2 mt-2">
                          <p className="font-m font-semibold text-sm">
                            Link To Target:{" "}
                          </p>
                          <div className="">
                            <p className="font-m font-light text-sm">
                              {a?.linkToTarget}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center mt-2">
                          <p className="font-m font-semibold text-sm">
                            Start Time:{" "}
                            <span className="font-m ml-2 font-light text-sm">
                              {new Date(a?.startTime * 1000).toLocaleString()}
                            </span>
                          </p>
                        </div>

                        <div className="flex items-center mt-2">
                          <p className="font-m font-semibold text-sm">
                            End Time:{" "}
                            <span className="font-m ml-2 font-light text-sm">
                              {new Date(a?.endTime * 1000).toLocaleString()}
                            </span>
                          </p>
                        </div>

                        <div className="flex items-center mt-2">
                          <p className="font-m font-semibold text-sm">
                            Days:{" "}
                            <span className="font-m ml-2 font-light text-sm">
                              {a?.days.toString(10)}
                            </span>
                          </p>
                        </div>

                        <div className="flex items-center mt-2">
                          <p className="font-m font-semibold text-sm">
                            Cost Per Day:{" "}
                            <span className="font-m ml-2 font-light text-sm">
                              {a?.costPerDay.toString(10)}
                            </span>
                          </p>
                        </div>

                        <div className="flex items-center mt-2">
                          <p className="font-m font-semibold text-sm">
                            Impression Per Day:{" "}
                            <span className="font-m ml-2 font-light text-sm">
                              {a?.impressionsPerDay.toString(10)}
                            </span>
                          </p>
                        </div>

                        <div className="flex items-center mt-2">
                          <p className="font-m font-semibold text-sm">
                            Budget:{" "}
                            <span className="font-m ml-2 font-light text-sm">
                              {a?.budget.toString(10)} Sol
                            </span>
                          </p>
                        </div>

                        <button
                          onClick={() => {
                            setData(a);
                            setShow(!show);
                          }}
                          className="py-2 mt-5 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
                        >
                          Update Ad
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Layout>
      {show && (
        <UpdateModal
          show={show}
          setShow={setShow}
          data={data}
          id={id}
          setId={setId}
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          mediaFileLink={mediaFileLink}
          setMediaFileLink={setMediaFileLink}
          linkToTarget={linkToTarget}
          setLinkToTarget={setLinkToTarget}
          updateAdCampaign={updateAdCampaign}
        />
      )}
    </>
  );
};

export default Advertiser;
