import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import UI from "./UI";

const Layout = ({ children, ui }) => {
  return (
    <div className="relative">
      {ui && (
        <div className="absolute top-0 left-0 w-[100dvw] h-full">
          <UI />
        </div>
      )}
      <div className="pt-10 z-10 mor-bg">
        <Header />
        <main className="px-28">{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
