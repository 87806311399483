import React from "react";

const Bother = () => {
  return (
    <div className="h-full flex items-center pt-20">
      <div>
        <div className="mb-20">
          <h1 className="font-m font-bold text-[4.5rem]">Why Bother?</h1>
          <p className="font-m font-light text-2xl">
            Problems With Traditional Advertisement Exchanges
          </p>
        </div>

        <div className="w-full flex items-center">
          <div className="flex-1 flex">
            <div className="w-[1px] bg-white rounded-full"></div>
            <div className="w-[35%] overflow-hidden">
              <div
                data-aos="fade-right"
                className="bg-[#0f2443] hover:bg-gradient-to-br hover:from-[#fc5c7d] hover:to-[#6a81fb] h-full p-5 rounded-r-lg border-[.5px] border-[#2aa0e1] transition-all duration-[1000]"
              >
                <h2 className="font-m text-[2rem] font-bold">1</h2>
                <h5 className="font-m font-semibold text-base mt-2">
                  Not Serving Web3:
                </h5>
                <p className="font-m font-light text-sm mt-5">
                  They don’t serve Web3 platforms and publishers
                </p>
              </div>
            </div>

            <div className="w-[1px] bg-white rounded-full ml-5"></div>

            <div className="w-[35%] overflow-hidden">
              <div
                data-aos="fade-right"
                className="bg-[#0f2443] hover:bg-gradient-to-br hover:from-[#fc5c7d] hover:to-[#6a81fb] h-full p-5 rounded-r-lg border-[.5px] border-[#2aa0e1] transition-all duration-[1000]"
              >
                <h2 className="font-m text-[2rem] font-bold">2</h2>
                <h5 className="font-m font-semibold text-base mt-2">
                  Limited Payment Options:
                </h5>
                <p className="font-m font-light text-sm mt-5">
                  They often do not support cryptocurrency transactions, which
                  are pivotal for Web3 ecosystems, coupled with inefficient
                  payment processes.
                </p>
              </div>
            </div>

            <div className="w-[1px] bg-white rounded-full ml-5"></div>

            <div className="w-[35%] overflow-hidden">
              <div
                data-aos="fade-right"
                className="bg-[#0f2443] hover:bg-gradient-to-br hover:from-[#fc5c7d] hover:to-[#6a81fb] h-full p-5 rounded-r-lg border-[.5px] border-[#2aa0e1] transition-all duration-[1000]"
              >
                <h2 className="font-m text-[2rem] font-bold">3</h2>
                <h5 className="font-m font-semibold text-base mt-2">
                  Economic Opacity:
                </h5>
                <p className="font-m font-light text-sm mt-5">
                  There's a lack of clear visibility into the financial aspects,
                  such as earnings, spending, and the fees involved, leaving
                  participants in the dark about the economic value chain.
                </p>
              </div>
            </div>

            <div className="w-[1px] bg-white rounded-full ml-5"></div>

            <div className="w-[35%] overflow-hidden">
              <div
                data-aos="fade-right"
                className="bg-[#0f2443] hover:bg-gradient-to-br hover:from-[#fc5c7d] hover:to-[#6a81fb] h-full p-5 rounded-r-lg border-[.5px] border-[#2aa0e1] transition-all duration-[1000]"
              >
                <h2 className="font-m text-[2rem] font-bold">4</h2>
                <h5 className="font-m font-semibold text-base mt-2">
                  Analytical Opacity:
                </h5>
                <p className="font-m font-light text-sm mt-5">
                  Both advertisers and publishers face a veil over crucial
                  metrics like impressions, clicks, and conversion rates,
                  hindering informed decision-making.
                </p>
              </div>
            </div>

            <div className="w-[1px] bg-white rounded-full ml-5"></div>
            <div className="w-[35%] overflow-hidden">
              <div
                data-aos="fade-right"
                className="bg-[#0f2443] hover:bg-gradient-to-br hover:from-[#fc5c7d] hover:to-[#6a81fb] h-full p-5 rounded-r-lg border-[.5px] border-[#2aa0e1] transition-all duration-[1000]"
              >
                <h2 className="font-m text-[2rem] font-bold">5</h2>
                <h5 className="font-m font-semibold text-base mt-2">
                  No Rewards for Users:
                </h5>
                <p className="font-m font-light text-sm mt-5">
                  End users, despite being the backbone of the advertising value
                  chain, do not receive tangible benefits, reflecting a
                  one-sided relationship that benefits platforms and
                  advertisers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bother;
