import React, { useEffect } from "react";

const UpdateModal = ({
  show,
  setShow,
  updateAdCampaign,
  data,
  id,
  setId,
  name,
  setName,
  description,
  setDescription,
  mediaFileLink,
  setMediaFileLink,
  linkToTarget,
  setLinkToTarget,
}) => {
  useEffect(() => {
    setId(data?.id);
    setName(data?.name);
    setDescription(data?.description);
    setMediaFileLink(data?.mediaFileLink);
    setLinkToTarget(data?.linkToTarget);
  }, [data]);

  const handleCancel = () => {
    setId("");
    setName("");
    setDescription("");
    setMediaFileLink("");
    setLinkToTarget("");

    setShow(false);
  };
  return (
    <div className="fixed h-[100dvh] w-[100dvw] top-0 left-0 z-[100000] flex items-center justify-center">
      <div className="bg-black border border-gray-400 rounded-lg py-5 px-10 z-[10000] w-[60%]">
        <h3 className="font-m font-bold text-base text-center">
          Update Ad Campaign
        </h3>

        <form
          onSubmit={(event) => {
            event.preventDefault();
            updateAdCampaign();
          }}
        >
          <div className="p-5 mt-5">
            <div className="flex gap-2 items-center justify-between mt-5">
              <p className="font-m font-semibold">Name : </p>
              <div className="w-[60%] flex">
                <input
                  className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="flex gap-2 justify-between mt-5">
              <p className="font-m font-semibold">Description : </p>
              <div className="w-[60%] flex">
                <textarea
                  className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                  rows={5}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            <div className="flex gap-2 items-center justify-between mt-5">
              <p className="font-m font-semibold">Media File Link : </p>
              <div className="w-[60%] flex">
                <input
                  className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                  type="text"
                  value={mediaFileLink}
                  onChange={(e) => setMediaFileLink(e.target.value)}
                />
              </div>
            </div>

            <div className="flex mt-5 gap-2 items-center justify-between">
              <p className="font-m font-semibold">Link to Target : </p>
              <div className="w-[60%] flex">
                <input
                  className="bg-black border border-[#4B4B4B] flex-1 rounded py-1 focus:outline-0 px-3 text-sm font-m"
                  type="text"
                  value={linkToTarget}
                  onChange={(e) => setLinkToTarget(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-center mt-14 gap-5">
              <button
                onClick={handleCancel}
                className="py-[.05rem] px-[.1rem] rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
              >
                <span className="bg-black rounded-lg py-[.45rem] px-[1.2rem]">
                  Cancel
                </span>
              </button>
              <button
                type="submit"
                className="py-2 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="bg-black opacity-65 fixed top-0 left-0 h-[100dvh] w-[100dvw]"></div>
    </div>
  );
};

export default UpdateModal;
