import React from "react";

const Solutions = () => {
  return (
    <div className="h-[90%] flex items-center">
      <div>
        <div className="mb-20">
          <h1 className="font-m font-bold text-[4.5rem]">This is Why.</h1>
          <p className="font-m font-light text-2xl">
            These are the Solutions Tri-Ad offers
          </p>
        </div>

        <div className="w-full flex items-center">
          <div className="flex-1 flex">
            <div className="w-[35%] overflow-hidden">
              <div
                data-aos="fade-left"
                className="bg-[#0f2443] hover:bg-gradient-to-br hover:from-[#fc5c7d] hover:to-[#6a81fb]  h-full p-5 rounded-l-lg border-[.5px] border-[#2aa0e1] transition-all duration-[1000]"
              >
                <h2 className="font-m text-[2rem] font-bold">1</h2>
                <h5 className="font-m font-semibold text-base mt-2">
                  Web3 & Web2 Friendly:
                </h5>
                <p className="font-m font-light text-sm mt-5">
                  Seamlessly serves both Web3 platforms (dApps, metaverse) and
                  traditional Web2 publishers, broadening audience reach for
                  advertisers.
                </p>
              </div>
            </div>

            <div className="w-[1px] bg-white rounded-full mr-5"></div>

            <div className="w-[35%] overflow-hidden">
              <div
                data-aos="fade-left"
                className="bg-[#0f2443] hover:bg-gradient-to-br hover:from-[#fc5c7d] hover:to-[#6a81fb]  h-full p-5 rounded-l-lg border-[.5px] border-[#2aa0e1] transition-all duration-[1000]"
              >
                <h2 className="font-m text-[2rem] font-bold">2</h2>
                <h5 className="font-m font-semibold text-base mt-2">
                  Crypto Payments:
                </h5>
                <p className="font-m font-light text-sm mt-5">
                  Integrates cryptocurrency transactions for efficiency and
                  convenience, utilizing Solana's fast and low-cost transaction
                  capabilities.
                </p>
              </div>
            </div>

            <div className="w-[1px] bg-white rounded-full mr-5"></div>

            <div className="w-[35%] overflow-hidden">
              <div
                data-aos="fade-left"
                className="bg-[#0f2443] hover:bg-gradient-to-br hover:from-[#fc5c7d] hover:to-[#6a81fb]  h-full p-5 rounded-l-lg border-[.5px] border-[#2aa0e1] transition-all duration-[1000]"
              >
                <h2 className="font-m text-[2rem] font-bold">3</h2>
                <h5 className="font-m font-semibold text-base mt-2">
                  Transparent Economics:
                </h5>
                <p className="font-m font-light text-sm mt-5">
                  Provides full visibility into earnings, spending, and fees,
                  building trust through blockchain transparency.
                </p>
              </div>
            </div>

            <div className="w-[1px] bg-white rounded-full mr-5"></div>

            <div className="w-[35%] overflow-hidden">
              <div
                data-aos="fade-left"
                className="bg-[#0f2443] hover:bg-gradient-to-br hover:from-[#fc5c7d] hover:to-[#6a81fb]  h-full p-5 rounded-l-lg border-[.5px] border-[#2aa0e1] transition-all duration-[1000]"
              >
                <h2 className="font-m text-[2rem] font-bold">4</h2>
                <h5 className="font-m font-semibold text-base mt-2">
                  Real-Time Analytics:
                </h5>
                <p className="font-m font-light text-sm mt-5">
                  Offers detailed, blockchain-verifiable insights into ad
                  performance metrics like impressions, clicks, and conversions.
                </p>
              </div>
            </div>

            <div className="w-[1px] bg-white rounded-full mr-5"></div>

            <div className="w-[35%] overflow-hidden">
              <div
                data-aos="fade-left"
                className="bg-[#0f2443] hover:bg-gradient-to-br hover:from-[#fc5c7d] hover:to-[#6a81fb]  h-full p-5 rounded-l-lg border-[.5px] border-[#2aa0e1] transition-all duration-[1000]"
              >
                <h2 className="font-m text-[2rem] font-bold">5</h2>
                <h5 className="font-m font-semibold text-base mt-2">
                  User Rewards:
                </h5>
                <p className="font-m font-light text-sm mt-5">
                  Rewarding users for ad interactions, sharing value creation
                  and enhancing engagement within the advertising ecosystem.
                </p>
              </div>
            </div>
            <div className="w-[1px] bg-white rounded-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
