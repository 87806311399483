import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Admin from "./pages/Admin";
import Advertiser from "./pages/Advertiser";
import Publisher from "./pages/Publisher";
import Site from "./pages/Site";
import AOS from "aos";
import "aos/dist/aos.css";

import { Buffer } from "buffer";
window.Buffer = Buffer;

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="App">
      <div className="full">
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/advertiser" element={<Advertiser />} />
            <Route path="/publisher" element={<Publisher />} />
            <Route path="/example" element={<Site />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default App;
