import React from "react";
import { l1, l2, l3, l4 } from "../../assets";

const UI = () => {
  return (
    <div className="relative h-full w-full overflow-hidden">
      <div className="absolute top-32 left-10 w-[20rem]">
        <img src={l1} alt="" className="float" />
      </div>

      <div className="absolute top-[600px] right-10 w-[20rem]">
        <img src={l2} alt="" className="float" />
      </div>

      <div className="absolute top-[1000px] left-10 w-[20rem]">
        <img src={l1} alt="" className="float" />
      </div>

      <div className="absolute top-[1550px] right-10 w-[20rem]">
        <img src={l3} alt="" className="float" />
      </div>

      <div className="absolute top-[2300px] left-[-100px] w-[20rem]">
        <img src={l4} alt="" className="float" />
      </div>

      <div className="absolute top-[2900px] right-10 w-[20rem]">
        <img src={l2} alt="" className="float" />
      </div>

      <div className="absolute top-[3500px] left-10 w-[20rem]">
        <img src={l1} alt="" className="float" />
      </div>

      <div className="absolute top-[3950px] right-[-100px] w-[20rem]">
        <img src={l3} alt="" className="float" />
      </div>

      <div className="absolute bottom-[-100px] left-10 w-[20rem]">
        <img src={l1} alt="" className="float" />
      </div>

      <div className="h-[500px] w-[500px] bg-[#815ac4] rounded-full blur absolute top-[-250px] left-[-250px]"></div>

      <div className="h-[500px] w-[500px] bg-[#6a81fb] rounded-full blur absolute top-[-250px] right-[-250px]"></div>
    </div>
  );
};

export default UI;
