import { ChevronRight } from "lucide-react";
import React from "react";

const Blockchain = () => {
  return (
    <div className="h-full pt-20">
      {/* head text */}
      <div className="flex justify-center">
        <div class="bg-gradient-to-r from-[#6a81fb] to-[#fc5c7d] h-20 flex items-center text-transparent bg-clip-text">
          <h1 className="font-m font-bold text-[4rem]">
            IT’S BETTER WITH BLOCKCHAIN
          </h1>
        </div>
      </div>

      <div className="mt-20 pl-20">
        <div className="w-[30%]">
          <p className="font-m font-semibold text-xl text-gray-400">
            An ad exchange is a marketing technology platform that enables
            advertisers and publishers to buy and sell advertising space from
            one or multiple ad networks.
          </p>
        </div>
      </div>

      <div className="absolute bottom-[-20%] right-20 h-[600px] w-[600px] rounded-full border-[18px] border-gray-400">
        <div className="h-full w-full relative">
          <div className="absolute left-[-23px] top-[-23px]">
            <ChevronRight
              size={200}
              color="#9ba3af"
              className=" rotate-[-45deg]"
            />
          </div>

          <div className="absolute bottom-[9rem] w-[40%] left-14">
            <p className="font-m font-base font-semibold text-white">
              It allows advertisers to reach a wider audience and publishers to
              monetize their content
            </p>
          </div>

          <div className="absolute top-20 w-[40%] right-12">
            <p className="font-m font-base font-semibold text-white">
              We use blockchain to transform a standard Ad exchange into a fully
              transparent process with assured security.
            </p>
          </div>

          <div className="bg-[#fc5c7d] rounded-full py-2 px-5 absolute top-1/2 translate-y-[-50%] left-[-100px]">
            <p className="font-m font-base font-semibold text-black">
              AD EXCHANGE
            </p>
          </div>

          <div className="bg-[#6a81fb] rounded-full py-2 px-5 absolute top-5 right-[-50px]">
            <p className="font-m font-base font-semibold text-black">
              DECENTRALIZED AD EXCHANGE
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blockchain;
