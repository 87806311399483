import React, { useEffect, useState } from "react";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";
import { Program, AnchorProvider, web3 } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";

import kp from "../keypair.json";

import { Buffer } from "buffer";
import Layout from "../components/layout/Layout";
import { ChevronsRight } from "lucide-react";
window.Buffer = Buffer;

const { SystemProgram } = web3;

//Admin
const arr = Object.values(kp._keypair.secretKey);
const secret = new Uint8Array(arr);
const baseAccount = web3.Keypair.fromSecretKey(secret);

const programID = new PublicKey("EQYu1bjXZt3jp5X7eZcjQv1q3ReB9MQKt597sGmu5Dqi"); // your program ID: 7qB4Yx1rtnyhz11KfikMZTSUcAhPdLu1ofunrkBWfhfM

const network = clusterApiUrl("devnet");

const opts = {
  preflightCommitment: "processed",
};

const Admin = () => {
  //Admin, Advertiser, Publisher
  const [walletAddress, setWalletAddress] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [newRate, setNewRate] = useState("");
  //Admin and Advertiser
  const [adList, setAdList] = useState([]);
  const [rate, setRate] = useState("");
  const [adCount, setAdCount] = useState("");
  // const creatorPublicKey = "EQZJKQYhcxTV3icAGbmYs9GGpr2eanwq4UvJKCXESRDh";
  const creatorPublicKey = walletAddress;

  //Admin, Advertiser, Publisher
  const checkIfWalletIsConnected = async () => {
    if (window?.solana?.isPhantom) {
      console.log("Phantom wallet found!");
      try {
        const response = await window.solana.connect({ onlyIfTrusted: true });
        console.log(
          "Connected with Public Key:",
          response.publicKey.toString()
        );
        setWalletAddress(response.publicKey.toString());
      } catch (error) {
        console.error("User rejected the request:", error);
      }
    } else {
      alert("Solana object not found! Get a Phantom Wallet 👻");
    }
  };

  const checkIfAdmin = () => {
    if (walletAddress === creatorPublicKey) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };

  //Admin, Advertiser, Publisher
  const connectWallet = async () => {
    const { solana } = window;
    if (solana) {
      try {
        const response = await solana.connect();
        console.log(
          "Connected with Public Key:",
          response.publicKey.toString()
        );
        setWalletAddress(response.publicKey.toString());
      } catch (error) {
        console.error("User rejected the request:", error);
      }
    }
  };

  //Admin
  const updatePayPerImpressionRate = async () => {
    if (!isAdmin || !newRate) {
      console.log(
        "Operation not allowed. You are either not an admin or the rate is not specified."
      );
      return; // Exit the function early
    }

    try {
      const provider = getProvider();
      const program = await getProgram();

      await program.rpc.updatePayPerImpressionRate(new anchor.BN(newRate), {
        accounts: {
          baseAccount: baseAccount.publicKey,
          authority: provider.wallet.publicKey,
        },
      });

      alert("Rate updated successfully!");
      setNewRate(""); // Resetting the input field after successful update
    } catch (error) {
      console.error("Failed to update the pay-per-impression rate:", error);
    }
  };

  //Admin, Advertiser, Publisher
  const getProvider = () => {
    const connection = new Connection(network, opts.preflightCommitment);
    const provider = new AnchorProvider(
      connection,
      window.solana,
      opts.preflightCommitment
    );
    return provider;
  };

  //Admin
  const createAdAccount = async () => {
    if (!isAdmin) {
      console.log(
        "Unauthorized attempt to create an ad account. You are not admin."
      );
      // Optionally, you could also use alert() to notify through the UI or handle this case more gracefully in your UI logic.
      return; // Exit the function early if the user is not an admin
    }

    try {
      const provider = getProvider();
      const program = await getProgram();

      await program.rpc.initialize({
        accounts: {
          baseAccount: baseAccount.publicKey,
          user: provider.wallet.publicKey,
          systemProgram: SystemProgram.programId,
        },
        signers: [baseAccount],
      });
      console.log("BaseAccount initialized successfully");

      await fetchAdList();
    } catch (error) {
      console.log("Error initializing BaseAccount:", error);
    }
  };

  //Admin, Advertiser, Publisher
  const renderNotConnectedContainer = () => (
    <button
      className="cta-button connect-wallet-button"
      onClick={connectWallet}
    >
      Connect to Wallet
    </button>
  );

  //Admin, Advertiser, Publisher
  const renderConnectedContainer = () => {
    if (!isAdmin) {
      return <p className="sub-text">You are not admin</p>;
    } else {
      if (adList === null) {
        return (
          <div className="connected-container">
            {isAdmin ? (
              <button
                className="cta-button submit-gif-button"
                onClick={createAdAccount}
              >
                Initialize Ad Account
              </button>
            ) : (
              <p>You are not admin</p>
            )}
          </div>
        );
      } else {
        return (
          <div className="connected-container">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updatePayPerImpressionRate();
              }}
            >
              <input
                type="number"
                value={newRate}
                onChange={(e) => setNewRate(e.target.value)}
                placeholder="New Pay-Per-Impression Rate"
              />
              <button type="submit" className="cta-button submit-gif-button">
                Update Rate
              </button>
            </form>
            <div>
              {isAdmin && (
                <div>
                  <button
                    className="cta-button submit-gif-button"
                    onClick={startNewDay}
                  >
                    Start New Day
                  </button>
                  <button
                    className="cta-button submit-gif-button"
                    onClick={removeExpiredAds}
                  >
                    Remove Expired Ads
                  </button>
                </div>
              )}
            </div>
            <div className="ad-list ad-item">
              {adList.map((ad, index) => (
                <div className="card" key={index}>
                  <br></br>
                  <a href={ad.linkToTarget}>
                    <img
                      className="image"
                      src={ad.mediaFileLink}
                      height={400}
                      alt=""
                    ></img>
                  </a>
                  <br></br>
                  <p>Ad ID: {ad.id}</p>
                  <p>Name: {ad.name}</p>
                  <p>Description: {ad.description}</p>
                  <p>Link To Target: {ad.linkToTarget}</p>
                  <p>
                    Start Time: {new Date(ad.startTime * 1000).toLocaleString()}
                  </p>
                  <p>
                    End Time: {new Date(ad.endTime * 1000).toLocaleString()}
                  </p>
                  <p>Days: {ad.days.toString(10)}</p>
                  <p>Cost Per Day: {ad.costPerDay.toString(10)}</p>
                  <p>Impression Per Day: {ad.impressionsPerDay.toString(10)}</p>
                  <p>
                    Impressions Count: {ad.impressionsCountPerDay.toString(10)}
                  </p>
                  <p>Budget: {ad.budget.toString(10)} Sol</p>
                  <p>Creator Address: {ad.userAddress.toString(10)}</p>
                  <br></br>
                </div>
              ))}
            </div>
          </div>
        );
      }
    }
  };

  //Admin, Advertiser, Publisher
  const getProgram = async () => {
    const idl = await Program.fetchIdl(programID, getProvider());
    return new Program(idl, programID, getProvider());
  };

  //Admin and Advertiser
  const fetchAdList = async () => {
    try {
      const program = await getProgram();
      const account = await program.account.baseAccount.fetch(
        baseAccount.publicKey
      );
      console.log("is ", isAdmin);
      console.log("Fetched ad list:", account.adList);
      setAdList(account.adList);
      setRate(account.payPerImpressionRate.toString());
      setAdCount(account.totalAds.toString());
    } catch (error) {
      console.log("Error fetching ad list:", error);
      setAdList(null);
    }
  };

  const startNewDay = async () => {
    if (!isAdmin) {
      console.log("Unauthorized: Only admins can start a new day.");
      return;
    }

    try {
      const provider = getProvider();
      const program = await getProgram();

      await program.rpc.startNewDay({
        accounts: {
          baseAccount: baseAccount.publicKey,
        },
      });

      console.log("New day started successfully.");
      await fetchAdList(); // Refresh the ad list to reflect any changes
    } catch (error) {
      console.error("Failed to start a new day:", error);
    }
  };

  const removeExpiredAds = async () => {
    if (!isAdmin) {
      console.log("Unauthorized: Only admins can remove expired ads.");
      return;
    }

    try {
      const provider = getProvider();
      const program = await getProgram();

      await program.rpc.removeExpiredAds({
        accounts: {
          baseAccount: baseAccount.publicKey,
        },
      });

      console.log("Expired ads removed successfully.");
      await fetchAdList(); // Refresh the ad list to reflect any changes
    } catch (error) {
      console.error("Failed to remove expired ads:", error);
    }
  };

  //Admin, Advertiser, Publisher
  useEffect(() => {
    const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
  }, []);

  //Admin and Advertiser
  useEffect(() => {
    if (walletAddress) {
      console.log("Fetching ad list...");
      fetchAdList();
    }
  }, [walletAddress]);

  useEffect(() => {
    // This useEffect now specifically checks if the walletAddress has changed and then verifies admin status.
    checkIfAdmin();
  }, [walletAddress]);

  //Admin, Advertiser, Publisher
  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  return (
    <Layout>
      <div className="min-h-[91dvh]">
        {!walletAddress ? (
          <div className="h-[91dvh] flex justify-center items-center">
            <button
              onClick={connectWallet}
              className="py-2 mt-10 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
            >
              Connect Wallet <ChevronsRight size={20} />
            </button>
          </div>
        ) : (
          <div className="py-10">
            <h1 className="text-center font-m font-bold text-xl">
              Admin Dashboard
            </h1>
            <div className="flex justify-center mt-3 ">
              <p className="font-m font-bold text-base bg-[#1F1F1F] px-5 rounded-sm">
                Wallet Address :{" "}
                <span className="font-light">{walletAddress}</span>
              </p>
            </div>
            <p className="text-center font-m font-semibold mt-2">
              Ad Count: <span className="font-m font-thin">{adCount}</span>
            </p>
            <p className="text-center font-m font-semibold mt-2">
              Current Rate/Impression:{" "}
              <span className="font-m font-thin">{rate} Sol</span>
            </p>

            <div className="mt-10">
              {isAdmin ? (
                <>
                  {adList === null ? (
                    <button
                      className="cta-button submit-gif-button"
                      onClick={createAdAccount}
                    >
                      Initialize Ad Account
                    </button>
                  ) : (
                    <div className="">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          updatePayPerImpressionRate();
                        }}
                      >
                        <div className="flex items-center justify-center gap-5">
                          <input
                            className="bg-black border border-[#4B4B4B] rounded py-2 focus:outline-0 px-3 text-sm font-m"
                            type="number"
                            value={newRate}
                            onChange={(e) => setNewRate(e.target.value)}
                            placeholder="New Pay-Per-Impression Rate"
                          />
                          <button
                            type="submit"
                            className="py-2 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
                          >
                            Update Rate
                          </button>
                        </div>
                      </form>
                      <div>
                        {isAdmin && (
                          <div className="flex items-center justify-center gap-5">
                            <button
                              className="py-2 mt-10 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
                              onClick={startNewDay}
                            >
                              Start New Day
                            </button>
                            <button
                              className="py-2 mt-10 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
                              onClick={removeExpiredAds}
                            >
                              Remove Expired Ads
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col gap-5 mt-10">
                        {adList.map((a) => (
                          <div
                            key={a?.id}
                            className="border border-gray-400 rounded-lg p-5 w-full "
                          >
                            <div className="mb-5">
                              <h2 className="italic font-bold text-center text-lg">
                                {a?.name}
                              </h2>

                              <p className="italic font-thin text-center text-xs line-clamp-3">
                                {a?.description}
                              </p>
                            </div>

                            <div className="gap-5 flex">
                              <div className="w-[300px] h-full rounded-md overflow-hidden">
                                <a href={a.linkToTarget} target="_blank">
                                  <img
                                    className=""
                                    src={a?.mediaFileLink}
                                    alt={a?.name}
                                  />
                                </a>
                              </div>

                              <div className="">
                                <div className="flex gap-2 items-center">
                                  <p className="font-m font-semibold text-sm">
                                    Ad ID:{" "}
                                  </p>
                                  <div className="">
                                    <p className="font-m font-light text-sm">
                                      {a?.id}
                                    </p>
                                  </div>
                                </div>

                                <div className="flex gap-2 mt-2">
                                  <p className="font-m font-semibold text-sm">
                                    Link To Target:{" "}
                                  </p>
                                  <div className="">
                                    <p className="font-m font-light text-sm">
                                      {a?.linkToTarget}
                                    </p>
                                  </div>
                                </div>

                                <div className="flex items-center mt-2">
                                  <p className="font-m font-semibold text-sm">
                                    Start Time:{" "}
                                    <span className="font-m ml-2 font-light text-sm">
                                      {new Date(
                                        a?.startTime * 1000
                                      ).toLocaleString()}
                                    </span>
                                  </p>
                                </div>

                                <div className="flex items-center mt-2">
                                  <p className="font-m font-semibold text-sm">
                                    End Time:{" "}
                                    <span className="font-m ml-2 font-light text-sm">
                                      {new Date(
                                        a?.endTime * 1000
                                      ).toLocaleString()}
                                    </span>
                                  </p>
                                </div>

                                <div className="flex items-center mt-2">
                                  <p className="font-m font-semibold text-sm">
                                    Days:{" "}
                                    <span className="font-m ml-2 font-light text-sm">
                                      {a?.days.toString(10)}
                                    </span>
                                  </p>
                                </div>

                                <div className="flex items-center mt-2">
                                  <p className="font-m font-semibold text-sm">
                                    Cost Per Day:{" "}
                                    <span className="font-m ml-2 font-light text-sm">
                                      {a?.costPerDay.toString(10)}
                                    </span>
                                  </p>
                                </div>

                                <div className="flex items-center mt-2">
                                  <p className="font-m font-semibold text-sm">
                                    Impression Per Day:{" "}
                                    <span className="font-m ml-2 font-light text-sm">
                                      {a?.impressionsPerDay.toString(10)}
                                    </span>
                                  </p>
                                </div>

                                <div className="flex items-center mt-2">
                                  <p className="font-m font-semibold text-sm">
                                    Budget:{" "}
                                    <span className="font-m ml-2 font-light text-sm">
                                      {a?.budget.toString(10)} Sol
                                    </span>
                                  </p>
                                </div>

                                <div className="flex gap-2 items-center mt-2">
                                  <p className="font-m font-semibold text-sm">
                                    Creator Address:{" "}
                                  </p>
                                  <div className="">
                                    <p className="font-m font-light text-sm">
                                      {a?.userAddress.toString(10)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <p className="italic text-center font-light">
                  You are not an admin
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Admin;
