import React from "react";
import { logo, logo2 } from "../../assets";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py-10 flex items-center justify-between mor-bg-2 px-28">
      <div className="w-[40%]">
        <div className="flex h-7">
          <img src={logo2} alt="Triad" />
          <img src={logo} alt="Triad" />
        </div>
        <p className="font-m font-light text-[.8rem] mt-2 w-[90%] text-gray-400">
          A decentralized ad exchange which uses blockchain to directly connect
          advertisers and publishers, offering lower fees and more control for
          everyone.
        </p>
      </div>

      <div className="flex items-center gap-10">
        <Link
          className="font-m font-light text-sm hover:font-semibold transition-all underline"
          to="/admin"
        >
          Admin Panel
        </Link>

        <Link
          className="font-m font-light text-sm hover:font-semibold transition-all underline"
          to="/publisher"
        >
          Publisher
        </Link>

        <Link
          className="font-m font-light text-sm hover:font-semibold transition-all underline"
          to="/advertiser"
        >
          Advertiser
        </Link>

        <Link
          className="font-m font-light text-sm hover:font-semibold transition-all underline"
          to="/example"
        >
          Example Site
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
