import React, { useEffect, useState } from "react";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";
import { Program, AnchorProvider, web3 } from "@project-serum/anchor";
import { ChevronsRight } from "lucide-react";

import { Buffer } from "buffer";
import Layout from "../components/layout/Layout";
import { hero } from "../assets";
import Team from "../components/Team";
import EarthCanvas from "../components/Earth";
import Strategy from "../components/Strategy";
import Solutions from "../components/Solutions";
import Bother from "../components/Bother";
import Blockchain from "../components/Blockchain";

window.Buffer = Buffer;

const { SystemProgram } = web3;

const programID = new PublicKey("EQYu1bjXZt3jp5X7eZcjQv1q3ReB9MQKt597sGmu5Dqi");

const network = clusterApiUrl("devnet");

const opts = {
  preflightCommitment: "processed",
};

const Landing = () => {
  const [walletAddress, setWalletAddress] = useState(null);

  const checkIfWalletIsConnected = async () => {
    if (window?.solana?.isPhantom) {
      console.log("Phantom wallet found!");
      try {
        const response = await window.solana.connect({ onlyIfTrusted: true });
        console.log(
          "Connected with Public Key:",
          response.publicKey.toString()
        );
        setWalletAddress(response.publicKey.toString());
      } catch (error) {
        console.error("User rejected the request:", error);
      }
    } else {
      alert("Solana object not found! Get a Phantom Wallet 👻");
    }
  };

  const connectWallet = async () => {
    const { solana } = window;
    if (solana) {
      try {
        const response = await solana.connect();
        console.log(
          "Connected with Public Key:",
          response.publicKey.toString()
        );
        setWalletAddress(response.publicKey.toString());
      } catch (error) {
        console.error("User rejected the request:", error);
      }
    }
  };

  const getProvider = () => {
    const connection = new Connection(network, opts.preflightCommitment);
    const provider = new AnchorProvider(
      connection,
      window.solana,
      opts.preflightCommitment
    );
    return provider;
  };

  const renderNotConnectedContainer = () => (
    <button
      className="cta-button connect-wallet-button"
      onClick={connectWallet}
    >
      Connect to Wallet
    </button>
  );

  const renderConnectedContainer = () => {
    return (
      <div className="connected-container">
        <p className="header">You can navigate the dapp</p>
      </div>
    );
  };

  const getProgram = async () => {
    const idl = await Program.fetchIdl(programID, getProvider());
    return new Program(idl, programID, getProvider());
  };

  useEffect(() => {
    const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
  }, []);

  //Admin, Advertiser, Publisher
  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  return (
    <Layout ui={true}>
      <section className="h-[91dvh] flex">
        <div className="grid grid-cols-2 w-full flex-1">
          <div className="h-full flex items-center">
            <div>
              <h1 className="font-m font-bold text-[6rem]">TRI-AD</h1>
              <p className="font-m font-semibold w-[85%]">
                Ditch the middleman! Triad is a{" "}
                <span className="bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] rounded-sm">
                  decentralized ad exchange
                </span>{" "}
                which uses blockchain to directly connect advertisers and
                publishers, offering lower fees and more control for everyone.
              </p>
              {walletAddress ? (
                <div className="flex gap-5 items-center">
                  <button
                    onClick={() => window.location.replace("/publisher")}
                    className="py-2 mt-10 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
                  >
                    Publisher Dashboard
                  </button>
                  <button
                    onClick={() => window.location.replace("/advertiser")}
                    className="py-2 mt-10 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
                  >
                    Advertiser Dashboard
                  </button>
                </div>
              ) : (
                <button
                  onClick={connectWallet}
                  className="py-2 mt-10 px-5 rounded-md bg-gradient-to-r from-[#fc5c7d] to-[#6a81fb] font-m font-semibold flex items-center gap-1 active:scale-[.98]"
                >
                  Connect Wallet <ChevronsRight size={20} />
                </button>
              )}
            </div>
          </div>
          <div className="h-full flex items-center">
            {/* <img src={hero} alt="Hero Image" /> */}
            <EarthCanvas />
          </div>
        </div>
        {/* {!walletAddress && renderNotConnectedContainer()}
            {walletAddress && renderConnectedContainer()} */}
      </section>

      <section className="h-[100dvh] relative overflow-hidden">
        <Blockchain />
      </section>

      <section className="h-[100dvh]">
        <Bother />
      </section>

      <section className="h-[100dvh]">
        <Solutions />
      </section>

      <section className="h-[100dvh]">
        <Strategy />
      </section>

      {/* team */}
      <section className="h-[100dvh]">
        <Team />
      </section>
    </Layout>
  );
};

export default Landing;
